<template>
  <section class="content">
    <div class="container-fluid">
      <!-- Main row -->
      <div class="row">
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert">

          </custom-alert>
          <div class="card">
            <div class="card-body  table-responsive table-head">
              <div class="row bor-bot pb-3">
                <div class="col-md-8 pl-0">
                  <h1 class="m-0 text-dark pt-2">Club Consolidate</h1>
                </div>
                <div class="col-md-4">
                  <div class="text-right pb-3">
                    <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item">
                        <Breadcrumbs/>
                      </li>
                    </ol>
                  </div>
                </div>


              </div>

              <div class="table-responsive">
                <b-overlay :show="showLoader" no-wrap></b-overlay>
                <table class="table table-sm table-bordered">
                  <thead>
                  <tr>
                    <th style="width: 350px"></th>
                    <th style="width: 396px">
                      <div class="form-group">
                        <strong>Delete</strong>
                        <select2ajax v-model="form.member_id1" id="member_id1" v-on:input="member1change($event)">
                        </select2ajax>
                        <!--
                                                   <Select2 v-model="form.member_id1" @change="member1change" :options="memberOption" :settings="{ placeholder: 'Click to select one', width: '100%' }" />
                        -->
                        <span class="text-danger">{{ errors.member_id1 }}</span>
                      </div>
                    </th>
                    <th style="width: 8px !important;"></th>
                    <th style="width: 396px">
                      <div class="form-group">
                        <strong>Keep</strong>
                        <select2ajax v-model="form.member_id2" id="member_id2" v-on:input="member2change($event)">
                        </select2ajax>
                        <span class="text-danger">{{ errors.member_id2 }}</span>
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Membership id</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.member_id"
                               v-model="registration1.id">
                      <input type="hidden" readonly class="form-control" name="mergeDelete.team_id"
                             v-model="memberPersonal1Details.id"></td>
                    <td></td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.member_id"
                               v-model="registration2.id">
                      <input type="hidden" readonly class="form-control" name="mergeDelete.team_id"
                             v-model="memberPersonal2Details.id">
                    </td>
                  </tr>
                  <tr>
                    <td>Club Name</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.firstName"
                               v-model="memberPersonal1Details.name"></td>
                    <td><a href="#" @click.prevent="dataCopy('name')" v-if="copy.name"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('name')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.firstName1"
                               v-model="memberPersonal2Details.name"><span
                        class="text-sm text-danger">{{ errors.name }}</span></td>
                  </tr>

                  <tr>
                    <td>Club E-Mail</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.email"
                               v-model="memberPersonal1Details.email"></td>
                    <td><a href="#" @click.prevent="dataCopy('email')" v-if="copy.email"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('email')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.email1"
                               v-model="memberPersonal2Details.email"><span
                        class="text-sm text-danger">{{ errors.email }}</span></td>
                  </tr>
                  <tr>
                    <td>Primary Club Type</td>
                    <td><select class="form-control" id="primary_type1" :disabled="1"
                                v-model="memberPersonal1Details.type">
                      <option value="">Select</option>
                      <option v-for="(typ, val) in clubType" :value="val" :key="val"
                              :selected="memberPersonal1Details.type == val">
                        {{ typ }}
                      </option>
                    </select></td>
                    <td><a href="#" @click.prevent="dataCopy('type')" v-if="copy.type"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('type')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><select :class="allClubIsLoaded?'form-control form-control2':'form-control'" :disabled="!allClubIsLoaded" id="primary_type2"
                                v-model="memberPersonal2Details.type">
                      <option value="">Select</option>
                      <option v-for="(typ, val) in clubType" :value="val" :key="val"
                              :selected="memberPersonal2Details.type == val">
                        {{ typ }}
                      </option>
                    </select><span class="text-sm text-danger">{{ errors.type }}</span></td>
                  </tr>
                  <tr>
                    <td>Secondary Club Type</td>
                    <td><select class="form-control" id="secondary_type1" :disabled="1"
                                v-model="memberPersonal1Details.secondary_type">
                      <option value="">Select</option>
                      <option v-for="(typ, val) in clubType" :value="val" :key="val"
                              :selected="memberPersonal1Details.secondary_type == val">
                        {{ typ }}
                      </option>
                    </select></td>
                    <td><a href="#" @click.prevent="dataCopy('secondary_type')" v-if="copy.secondary_type"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('secondary_type')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><select :class="allClubIsLoaded?'form-control form-control2':'form-control'" :disabled="!allClubIsLoaded" id="secondary_type2"
                                v-model="memberPersonal2Details.secondary_type">
                      <option value="">Select</option>
                      <option v-for="(typ, val) in clubType" :value="val" :key="val"
                              :selected="memberPersonal2Details.secondary_type == val">
                        {{ typ }}
                      </option>
                    </select></td>

                  </tr>
                  <tr>
                    <td>Club Website</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.address"
                               v-model="memberPersonal1Details.website"></td>
                    <td><a href="#" @click.prevent="dataCopy('website')" v-if="copy.website"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('website')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.website"
                               v-model="memberPersonal2Details.website"><span
                        class="text-sm text-danger">{{ errors.website }}</span></td>
                  </tr>
                  <tr>
                    <td>Club Mailing Address</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.address"
                               v-model="memberPersonal1Details.address"></td>
                    <td><a href="#" @click.prevent="dataCopy('address')" v-if="copy.address"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('address')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.address1"
                               v-model="memberPersonal2Details.address"><span
                        class="text-sm text-danger">{{ errors.address }}</span></td>
                  </tr>
                  <tr>
                    <td>Address 2</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.address2"
                               v-model="memberPersonal1Details.address2"></td>
                    <td><a href="#" @click.prevent="dataCopy('address2')" v-if="copy.address2"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('address2')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.address2"
                               v-model="memberPersonal2Details.address2"></td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td><input type="text" readonly class="form-control" name="mergeDelete.City"
                               v-model="memberPersonal1Details.city">
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('city')" v-if="copy.city"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('city')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.city1"
                               v-model="memberPersonal2Details.city">
                      <span class="text-sm text-danger">{{ errors.city }}</span></td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>
                      <select class="form-control" id="state" name="memberContact1Details.state"
                              v-model="memberPersonal1Details.state_code" :disabled="1">
                        <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                :value="listOfState.state_code"
                                :selected="memberPersonal1Details.state_code == listOfState.state_code">
                          {{ listOfState.state }}
                        </option>
                      </select>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('state_code')" v-if="copy.state_code"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('state_code')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td>

                      <select :class="allClubIsLoaded?'form-control form-control2':'form-control'" :disabled="!allClubIsLoaded" id="state" name="memberContact1Details.state"
                              v-model="memberPersonal2Details.state_code">
                        <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                :value="listOfState.state_code"
                                :selected="memberPersonal2Details.state_code == listOfState.state_code">
                          {{ listOfState.state }}
                        </option>
                      </select><span class="text-sm text-danger">{{ errors.state_code }}</span></td>
                  </tr>
                  <tr>
                    <td>Zip/Postal Code</td>
                    <td>
                      <input type="text" readonly class="form-control" name="mergeDelete.zip"
                             v-model="memberPersonal1Details.zip"></td>
                    <td><a href="#" @click.prevent="dataCopy('zip')" v-if="copy.zip"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('zip')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td><input type="text" :class="allClubIsLoaded?'form-control form-control2':'form-control'" :readonly="!allClubIsLoaded" name="mergeDelete.zip1"
                               v-model="memberPersonal2Details.zip"><span
                        class="text-sm text-danger">{{ errors.zip }}</span></td>
                  </tr>
                  <tr>
                    <td>Site Information</td>
<!--                    <td>
                      <span v-if="clubSites1"><span v-for="site1 in clubSites1" :key="site1.id">{{ site1.school }} <br> </span></span>
                    </td>-->
                    <td :class="clubSites1==null||Object.keys(clubSites1).length==0?'gray-out-td':''">
                      <template v-for="site1 in clubSites1">
                        <b-card
                            no-body
                            class="mb-1"
                            :key="site1.id"
                        >
                          <div class="d-flex">
                            <b-form-checkbox
                                :id="'clubSites1'+site1.id"
                                v-model="form.clubSites1"
                                :checked="form.clubSites1[i]==site1.id?site1.id:false"
                                :value="site1.id"
                                :name="site1.id+',clubSites1'"
                                @click.native="contactChange"
                            ></b-form-checkbox>
                          <b-card-header style="width: 100%;" header-tag="header" class="p-1" role="tab">
                            <span block v-b-toggle="'accordion1-' + site1.id" @click.prevent="collapseDeleteChange(site1.id)" variant="info">{{
                                clubSitesschool[site1.count]
                              }}
                              <i :key="delete_si_key" :class="delete_si_collapse[site1.id]?'fa pull-right p-1 fa-chevron-left':'fa pull-right p-1 fa-chevron-down'"></i>
                            </span>
                          </b-card-header>
                          </div>
                          <b-collapse :id="'accordion1-' + site1.id" accordion="my-accordion"
                                      role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <label>Specific site/body of water of all club activities</label>
                                <input type="text" class="form-control" name="mergeDelete.site"
                                       v-model=" clubSitesschool[site1.count]" disabled>
                                <label>This site is the clubs primary/home ski site.</label>
                                <input readonly class="form-check-input club_discipilines_check" disabled
                                       type="checkbox" name="site1.is_primary" style="margin-left: 10px !important;"
                                       v-model="clubSitesis_primary[site1.count]"><br>
                                <label>Physical Address</label>
                                <input type="text" readonly class="form-control" name="mergeDelete.address"
                                       v-model="clubSitesaddress[site1.count]">
                                <label>Address 2</label>
                                <input type="text" readonly class="form-control" name="mergeDelete.address2"
                                       v-model="clubSitesaddress2[site1.count]">
                                <label>City</label>
                                <input type="text" readonly class="form-control" name="mergeDelete.city"
                                       v-model="clubSitescity[site1.count]">
                                <label>State</label>
                                <select class="form-control" name="mergeDelete.state" disabled
                                        v-model="clubSitesstate_code[site1.count]">
                                  <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                          :value="listOfState.state_code"
                                          :selected="site1.state_code == listOfState.state_code">
                                    {{ listOfState.state }}
                                  </option>
                                </select>
                                <label>Zip/Postal Code</label>
                                <input type="text" readonly class="form-control" name="mergeDelete.city"
                                       v-model="clubSiteszip[site1.count]">
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </template>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('clubSites')" v-if="copy.clubSites"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('clubSites')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="clubSites==null||Object.keys(clubSites).length==0?'gray-out-td':''">
                        <template v-for="newSite in clubSites">
                        <b-card
                            no-body
                            class="mb-1"
                            :key="newSite.id"
                        >
                          <div class="d-flex">
                            <b-form-checkbox
                                :id="'clubSites'+newSite.id"
                                v-model="form.clubSites"
                                :checked="form.clubSites[i]==newSite.id?newSite.id:false"
                                :value="newSite.id"
                                :name="newSite.id+',clubSites'"
                                @click.native="contactChange"
                            ></b-form-checkbox>
                          <b-card-header style="width: 100%;" header-tag="header" class="p-1" role="tab">
                            <span block v-b-toggle="'accordion-' + newSite.id" @click.prevent="collapseChange(newSite.id)" variant="info">{{newSite.school }}<i :key="keep_si_key" :class="keep_si_collapse[newSite.id]?'fa pull-right p-1 fa-chevron-left':'fa pull-right p-1 fa-chevron-down'"></i></span>
                          </b-card-header>
                            </div>
                          <b-collapse :id="'accordion-' + newSite.id" accordion="my-accordion"
                                      role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <label>Specific site/body of water of all club activities</label>
                                <input type="text" class="form-control form-control2" name="mergeDelete.site"
                                       v-model="newSite.school">
                                <label>This site is the clubs primary/home ski site.</label>
                                <input  class="form-check-input club_discipilines_check"
                                        type="checkbox" name="site1.is_primary" style="margin-left: 10px !important;"
                                        v-model="newSite.is_primary"><br>
                                <label>Physical Address</label>
                                <input type="text" class="form-control form-control2" name="mergeDelete.address"
                                       v-model="newSite.address">
                                <label>Address 2</label>
                                <input type="text" class="form-control form-control2" name="mergeDelete.address2"
                                       v-model="newSite.address2">
                                <label>City</label>
                                <input type="text" class="form-control form-control2" name="mergeDelete.city"
                                       v-model="newSite.city">
                                <label>State</label>
                                <select class="form-control form-control2" name="mergeDelete.state"
                                        v-model="newSite.state_code">
                                  <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                          :value="listOfState.state_code"
                                          :selected="newSite.state_code == listOfState.state_code">
                                    {{ listOfState.state }}
                                  </option>
                                </select>
                                <label>Zip/Postal Code</label>
                                <input type="text" class="form-control form-control2" name="mergeDelete.zip"
                                       v-model="newSite.zip">
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </template>
                      <i v-if="is_add_contact_visible" class="fa fa-plus text-left" @click="addSiteInformation" aria-hidden="true"></i>
                      <span
                          class="text-sm text-danger" v-if="errors.clubSite"><br>{{ errors.clubSite }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Primary Contact</td>
                    <td :class="primaryContact1==null?'gray-out-td':''"><span v-if="primaryContact1">{{ primaryContact1.first_name }} {{ primaryContact1.last_name }} - {{ primaryContact1.membership_id }}  </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('primaryContact')" v-if="copy.primaryContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('primaryContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="primaryContact2==null||primaryContact2.membership_id==''?'gray-out-td':''"><span v-if="primaryContact2.first_name">{{ primaryContact2.first_name }} {{ primaryContact2.last_name }} - {{ primaryContact2.membership_id }} </span><span
                          class="text-sm text-danger">{{ errors.primaryContact }}</span> <i v-if="contact.status" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('primaryContact2','Primary Contact Information')"></i></td>
                  </tr>

                  <tr>
                    <td>President</td>
                    <td :class="presidentContact1==null?'gray-out-td':''"><span v-if="presidentContact1">{{ presidentContact1.first_name }} {{ presidentContact1.last_name }} - {{ presidentContact1.membership_id }}  </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('presidentContact')" v-if="copy.presidentContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('presidentContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="presidentContact2==null||Object.keys(presidentContact2).length==0?'gray-out-td':''"><span v-if="presidentContact2&&Object.keys(presidentContact2).length!=0">
                      <label class="ml-2" v-bind:key="presidentContact2.id">
                        <b-form-checkbox
                            :id="'presidentContact2'+presidentContact2.membership_id"
                            v-model="form.presidentContact2"
                            :checked="form.presidentContact2"
                            :value="form.presidentContact2"
                            :name="presidentContact2.membership_id+',presidentContact2'"
                            @click.native="memberRemove"
                        >
                        {{ presidentContact2.first_name }} {{ presidentContact2.last_name }} - {{ presidentContact2.membership_id }} </b-form-checkbox> </label></span><span
                          class="text-sm text-danger">{{ errors.presidentContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('presidentContact2','President\'s Information')"></i></td>
                  </tr>
                  <tr>
                    <td>Vice President</td>
                    <td :class="vpContact1==null?'gray-out-td':''"><span v-if="vpContact1">{{ vpContact1.first_name }} {{ vpContact1.last_name }} - {{ vpContact1.membership_id }}  </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('vpContact')" v-if="copy.vpContact"><i class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#" @click.prevent="oldData('vpContact')"><i class="fas fa-undo icon-design"></i></a></td>
                    <td :class="vpContact2==null||Object.keys(vpContact2).length==0?'gray-out-td':''"><span v-if="vpContact2&&Object.keys(vpContact2).length!=0">
                      <label class="ml-2" v-bind:key="vpContact2.id">
                         <b-form-checkbox
                             :id="'vpContact2'+vpContact2.membership_id"
                             v-model="form.vpContact2"
                             :checked="form.vpContact2"
                             :value="form.vpContact2"
                             :name="vpContact2.membership_id+',vpContact2'"
                             @click.native="memberRemove"
                         >
                      {{ vpContact2.first_name }} {{ vpContact2.last_name }} - {{ vpContact2.membership_id }} </b-form-checkbox> </label></span> <span
                          class="text-sm text-danger">{{ errors.vpContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('vpContact2','Vice President\'s Information')"></i></td>
                  </tr>
                  <tr>
                    <td>Secretary</td>
                    <td :class="secretaryContact1==null?'gray-out-td':''"><span v-if="secretaryContact1">{{ secretaryContact1.first_name }} {{ secretaryContact1.last_name }} - {{ secretaryContact1.membership_id }}  </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('secretaryContact')" v-if="copy.secretaryContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('secretaryContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="secretaryContact2==null||Object.keys(secretaryContact2).length==0?'gray-out-td':''"><span v-if="secretaryContact2&&Object.keys(secretaryContact2).length!=0">
                      <label class="ml-2" v-bind:key="secretaryContact2.id">
                        <b-form-checkbox
                            :id="'secretaryContact2'+secretaryContact2.membership_id"
                            v-model="form.secretaryContact2"
                            :checked="form.secretaryContact2"
                            :value="form.secretaryContact2"
                            :name="treasurerContact2.membership_id+',secretaryContact2'"
                            @click.native="memberRemove"
                        >{{ secretaryContact2.first_name }} {{ secretaryContact2.last_name }} - {{ secretaryContact2.membership_id }} </b-form-checkbox> </label></span> <span
                          class="text-sm text-danger">{{ errors.secretaryContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('secretaryContact2','Secretary Information')"></i></td>
                  </tr>
                  <tr>
                    <td>Treasurer</td>
                    <td :class="treasurerContact1==null?'gray-out-td':''"><span v-if="treasurerContact1">{{ treasurerContact1.first_name }} {{ treasurerContact1.last_name }} - {{ treasurerContact1.membership_id }}  </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('treasurerContact')" v-if="copy.treasurerContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('treasurerContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="treasurerContact2==null||Object.keys(treasurerContact2).length==0?'gray-out-td':''"><span v-if="treasurerContact2&&Object.keys(treasurerContact2).length!=0">
                      <label class="ml-2" v-bind:key="treasurerContact2.id">
                        <b-form-checkbox
                            :id="'treasurerContact2'+treasurerContact2.membership_id"
                            v-model="form.treasurerContact2"
                            :checked="form.treasurerContact2"
                            :value="form.treasurerContact2"
                            :name="treasurerContact2.membership_id+',treasurerContact2'"
                            @click.native="memberRemove"
                        >
                      {{ treasurerContact2.first_name }} {{ treasurerContact2.last_name }} - {{ treasurerContact2.membership_id }} </b-form-checkbox> </label></span>
                      <span
                          class="text-sm text-danger">{{ errors.treasurerContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('treasurerContact2','Treasurer Information')"></i></td>
                  </tr>
                  <tr>
                    <td>Safe Sport Coordinator(s)</td>
                    <td :class="safeSportContact1==null||Object.keys(safeSportContact1).length==0?'gray-out-td':''"><span v-if="safeSportContact1"><span v-for="safeSport1 in safeSportContact1" :key="safeSport1.id">{{ safeSport1.first_name }} {{ safeSport1.last_name }} - {{ safeSport1.membership_id }} <br> </span> </span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('safeSportContact')" v-if="copy.safeSportContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('safeSportContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="safeSportContact2==null||Object.keys(safeSportContact2).length==0?'gray-out-td':''"><span v-if="safeSportContact2">
                      <label class="ml-2" v-for="(safeSport2,i) in safeSportContact2" v-bind:key="safeSport2.id">
                        <b-form-checkbox
                            :id="'safeSport2'+safeSport2.membership_id"
                            v-model="form.safeSport"
                            :checked="form.safeSport[i]==safeSport2.membership_id?safeSport2.membership_id:false"
                            :value="safeSport2.membership_id"
                            :name="safeSport2.membership_id+',safeSport'"
                            @click.native="contactChange"
                        >
                        {{ safeSport2.first_name }} {{ safeSport2.last_name }} - {{ safeSport2.membership_id }} </b-form-checkbox> </label></span><span
                          class="text-sm text-danger">{{ errors.safeSportContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('safeSportContact2','Safe Sport Coordinator')"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Junior Development Leader(s)</td>
                    <td :class="juniorContact1==null||Object.keys(juniorContact1).length==0?'gray-out-td':''">
                      <span v-if="juniorContact1"><span v-for="junior1 in juniorContact1" :key="junior1.id">{{ junior1.first_name }} {{ junior1.last_name }} - {{ junior1.membership_id }} <br> </span></span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('juniorContact')" v-if="copy.juniorContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('juniorContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="juniorContact2==null||Object.keys(juniorContact2).length==0?'gray-out-td':''"><span v-if="juniorContact2">
                      <label class="ml-2" v-for="(junior2,i) in juniorContact2" v-bind:key="junior2.id">
                        <b-form-checkbox
                            :id="'junior2'+junior2.membership_id"
                            v-model="form.junior"
                            :checked="form.junior[i]==junior2.membership_id?junior2.membership_id:false"
                            :value="junior2.membership_id"
                            :name="junior2.membership_id+',junior'"
                            @click.native="contactChange"
                        >{{ junior2.first_name }} {{ junior2.last_name }} - {{ junior2.membership_id }} </b-form-checkbox>
                      </label>
                    </span> <span
                          class="text-sm text-danger">{{ errors.juniorDevelopment }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('juniorContact2','Junior Development Leader(s)')"></i></td>
                  </tr>
                  <tr>
                    <td>Show Director(s)</td>
                    <td :class="skiContact1==null||Object.keys(skiContact1).length==0?'gray-out-td':''">
                      <span v-if="skiContact1"><span v-for="ski1 in skiContact1" :key="ski1.id"> {{ ski1.first_name }} {{ ski1.last_name }} - {{ ski1.membership_id }} <br> </span></span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('skiContact')" v-if="copy.skiContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('skiContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="skiContact2==null||Object.keys(skiContact2).length==0?'gray-out-td':''"><span v-if="skiContact2">
                      <label class="ml-2" v-for="(ski2,i) in skiContact2" v-bind:key="ski2.id">
                        <b-form-checkbox
                            :id="'ski2'+ski2.membership_id"
                            v-model="form.ski"
                            :checked="form.ski[i]==ski2.membership_id?ski2.membership_id:false"
                            :value="ski2.membership_id"
                            :name="ski2.membership_id+',ski'"
                            @click.native="contactChange"
                        >{{ ski2.first_name }} {{ ski2.last_name }} - {{ ski2.membership_id }} </b-form-checkbox>
                      </label>
                    </span><span
                          class="text-sm text-danger">{{ errors.skiContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('skiContact2','Show Director(s)')"></i></td>
                  </tr>
                  <tr>
                    <td>Assistant Show Director(s)</td>
                    <td :class="assistantContact1==null||Object.keys(assistantContact1).length==0?'gray-out-td':''">
                      <span v-if="assistantContact1"><span v-for="assistant1 in assistantContact1" :key="assistant1.id">{{ assistant1.first_name }} {{ assistant1.last_name }} - {{ assistant1.membership_id }} <br> </span></span>
                    </td>
                    <td><a href="#" @click.prevent="dataCopy('assistantContact')" v-if="copy.assistantContact"><i
                        class="fas fa-arrow-alt-circle-right icon-design"></i></a> <a v-else href="#"
                                                                                      @click.prevent="oldData('assistantContact')"><i
                        class="fas fa-undo icon-design"></i></a></td>
                    <td :class="assistantContact2==null||Object.keys(assistantContact2).length==0?'gray-out-td':''">
                    <span v-if="assistantContact2">
                      <label class="ml-2" v-for="(assistant2,i) in assistantContact2" v-bind:key="assistant2.id">
                        <b-form-checkbox
                            :id="'assistant2'+assistant2.membership_id"
                            v-model="form.assistant"
                            :checked="form.assistant[i]==assistant2.membership_id?assistant2.membership_id:false"
                            :value="assistant2.membership_id"
                            :name="assistant2.membership_id+',assistant'"
                            @click.native="contactChange"
                        >{{ assistant2.first_name }} {{ assistant2.last_name }} - {{ assistant2.membership_id }} </b-form-checkbox>
                      </label>
                    </span><span
                          class="text-sm text-danger">{{ errors.assistantContact }}</span> <i v-if="is_add_contact_visible" class="fa fa-plus text-left" aria-hidden="true" @click="addContact('assistantContact2','Assistant Show Director(s)')"></i></td>
                  </tr>
                  <tr v-show="false">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><span v-if="allClubIsLoaded">
                      <label class="ml-2" v-bind:key="1">
                        <b-form-checkbox
                            id="mergeClubData1"
                            v-model="form.mergeClubData"
                            :checked="form.mergeClubData.includes('insurance')?true:false"
                            value="insurance"
                            name="'insurance,mergeClubData'"
                            @click.native="mergeClubDataChange"
                        > Merge Insurances</b-form-checkbox>
                      </label>
                       <label class="ml-2" v-bind:key="1">
                        <b-form-checkbox
                            id="mergeClubData2"
                            v-model="form.mergeClubData"
                            :checked="form.mergeClubData.includes('sanction')?true:false"
                            value="sanction"
                            name="'sanction,mergeClubData'"
                            @click.native="mergeClubDataChange"
                        > Merge Sanctions</b-form-checkbox>
                      </label>
                      <label class="ml-2" v-bind:key="1">
                        <b-form-checkbox
                            id="mergeClubData3"
                            v-model="form.mergeClubData"
                            :checked="form.mergeClubData.includes('teamroster')?true:false"
                            value="teamroster"
                            name="'teamroster,mergeClubData'"
                            @click.native="mergeClubDataChange"
                        > Merge Club Member Rosters</b-form-checkbox>
                      </label>
                    </span></td>
                  </tr>
                  <div class="d-flex align-items-center"  v-for="menu in getSiteProps('permissionMenuList.permissions')" :key="menu.id">
                    <div :class="menu.isSubmenu?'mb-3 col-md-4':'col-md-4'">
                      <label :class="menu.isSubmenu?'mt-3   font-weight-bold form-label':'form-label text-right'"> {{menu.text}} </label>
                    </div>
                  </div>
                  </tbody>
                </table>
                <div class="text-center">
                  <button type="button" @click="mergeAndDelete()"
                          class="btn btn-secondary btnnextstp btndisabled btn-next-success">Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
    <div>
      <b-modal ref="verify-modal" size="xl" hide-footer>
        <div class="d-block">
          <h4>Verify All Data</h4>
          <table class="table table-lg table-bordered">
            <th>Field</th>
            <th>Delete</th>
            <th>Keep</th>
            <tr>
              <td>Member id</td>
              <td>{{ form.member_id1 }}</td>
              <td>{{ form.member_id2 }}</td>
            </tr>
            <tr>
              <td>Club Name</td>
              <td>{{ memberPersonal1Details.name }}</td>
              <td>{{ memberPersonal2Details.name }}</td>
            </tr>
            <tr>
              <td>Primary Club Type</td>
              <td>
                <div v-for="(club, index) in clubType" :key="index">
                  <div v-if="index==memberPersonal1Details.type">{{ club }}</div>
                </div>
              </td>
              <td>
                <div v-for="(club, index) in clubType" :key="index">
                  <div v-if="index==memberPersonal2Details.type">{{ club }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Secondary Club Type</td>
              <td>
                <div v-for="(club, index) in clubType" :key="index">
                  <div v-if="index==memberPersonal1Details.secondary_type">{{ club }}</div>
                </div>
              </td>
              <td>
                <div v-for="(club, index) in clubType" :key="index">
                  <div v-if="index==memberPersonal2Details.secondary_type">{{ club }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Club Website</td>
              <td>{{ memberPersonal1Details.website }}</td>
              <td>{{ memberPersonal2Details.website }}</td>
            </tr>
            <tr>
              <td>Club E-Mail</td>
              <td>{{ memberPersonal1Details.email }}</td>
              <td>{{ memberPersonal2Details.email }}</td>
            </tr>
            <tr>
              <td>Club Mailing Address</td>
              <td>{{ memberPersonal1Details.address }}</td>
              <td>{{ memberPersonal2Details.address }}</td>
            </tr>
            <tr>
              <td>Address2</td>
              <td>{{ memberPersonal1Details.address2 }}</td>
              <td>{{ memberPersonal2Details.address2 }}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{{ memberPersonal1Details.state_code }}</td>
              <td>{{ memberPersonal2Details.state_code }}</td>
            </tr>
            <tr>
              <td>Zip/Postal Code</td>
              <td>{{ memberPersonal1Details.zip }}</td>
              <td>{{ memberPersonal2Details.zip }}</td>
            </tr>
            <tr>
              <td>Site Information</td>
              <td><span v-if="clubSites1"><span v-for="site1 in clubSites1" :key="site1.id">{{ clubSitesschool[site1.count] }} <br> </span></span></td>
              <td><span v-if="clubSitesCopy"><span v-for="site2 in clubSitesCopy" :key="site2.id">{{ site2.school }} <br> </span></span></td>
            </tr>
            <tr>
              <td>Primary Contact</td>
              <td><span v-if="primaryContact1">{{ primaryContact1.first_name }} {{ primaryContact1.last_name }} - {{ primaryContact1.membership_id }}  </span></td>
              <td><span v-if="primaryContact2">{{ primaryContact2.first_name }} {{ primaryContact2.last_name }} - {{ primaryContact2.membership_id }}</span></td>
            </tr>
            <tr>
              <td>President</td>
              <td><span v-if="presidentContact1">{{ presidentContact1.first_name }} {{ presidentContact1.last_name }} - {{ presidentContact1.membership_id }}  </span></td>
              <td><span v-if="form.presidentContact2&&presidentContact2&&presidentContact2.first_name">{{ presidentContact2.first_name }} {{ presidentContact2.last_name }} - {{ presidentContact2.membership_id }}</span></td>
            </tr>
            <tr>
              <td>Vice President</td>
              <td><span v-if="vpContact1">{{ vpContact1.first_name }} {{ vpContact1.last_name }} - {{ vpContact1.membership_id }}  </span></td>
              <td><span v-if="form.vpContact2&&vpContact2&&vpContact2.first_name">{{ vpContact2.first_name }} {{ vpContact2.last_name }} - {{ vpContact2.membership_id }}</span></td>
            </tr>
            <tr>
              <td>Secretary</td>
              <td><span v-if="secretaryContact1">{{ secretaryContact1.first_name }} {{ secretaryContact1.last_name }} - {{ secretaryContact1.membership_id }}  </span></td>
              <td><span v-if="form.secretaryContact2&&secretaryContact2&&secretaryContact2&&secretaryContact2.first_name">{{ secretaryContact2.first_name }} {{ secretaryContact2.last_name }} - {{ secretaryContact2.membership_id }}</span></td>
            </tr>
            <tr>
              <td>Treasurer</td>
              <td><span v-if="treasurerContact1">{{ treasurerContact1.first_name }} {{ treasurerContact1.last_name }} - {{ treasurerContact1.membership_id }}  </span></td>
              <td><span v-if="form.treasurerContact2&&treasurerContact2&&treasurerContact2.first_name">{{ treasurerContact2.first_name }} {{ treasurerContact2.last_name }} - {{ treasurerContact2.membership_id }}</span></td>
            </tr>
            <tr>
              <td>Safe Sport Coordinator(s)</td>
              <td><span v-if="safeSportContact1"><span v-for="safeSport1 in safeSportContact1" :key="safeSport1.id">{{ safeSport1.first_name }} {{ safeSport1.last_name }} - {{ safeSport1.membership_id }} <br> </span> </span></td>
              <td>
                <span v-if="safeSportContactCopy"><span v-for="safeSport2 in safeSportContactCopy" :key="safeSport2.id">{{ safeSport2.first_name }} {{ safeSport2.last_name }} - {{ safeSport2.membership_id }}<br></span></span></td>
            </tr>
            <tr>
            <td>Junior Development Leader(s)</td>
            <td>
              <span v-if="juniorContact1"><span v-for="junior1 in juniorContact1" :key="junior1.id">{{ junior1.first_name }} {{ junior1.last_name }} - {{ junior1.membership_id }} <br> </span></span>
            </td>
              <td>
                <span v-if="juniorContactCopy"><span v-for="junior2 in juniorContactCopy" :key="junior2.id">{{ junior2.first_name }} {{ junior2.last_name }} - {{ junior2.membership_id }} <br> </span></span>
              </td>
            </tr>
            <tr>
              <td>Show Director(s)</td>
              <td>
                <span v-if="skiContact1"><span v-for="ski1 in skiContact1" :key="ski1.id">{{ ski1.first_name }} {{ ski1.last_name }} - {{ ski1.membership_id }} <br> </span></span>
              </td>
              <td>
                <span v-if="skiContactCopy"><span v-for="ski2 in skiContactCopy" :key="ski2.id">{{ ski2.first_name }} {{ ski2.last_name }} - {{ ski2.membership_id }} <br> </span></span>
              </td>
            </tr>
            <tr>
              <td>Assistant Show Director(s)</td>
              <td>
                <span v-if="assistantContact1"><span v-for="assistant in assistantContact1" :key="assistant.id">{{ assistant.first_name }} {{ assistant.last_name }} - {{ assistant.membership_id }} <br> </span></span>
              </td>
              <td>
                <span v-if="assistantContactCopy"><span v-for="assistant2 in assistantContactCopy" :key="assistant2.id">{{ assistant2.first_name }} {{ assistant2.last_name }} - {{ assistant2.membership_id }} <br> </span></span>
              </td>
            </tr>

          </table>
          <div>
            <label class="text-left">Note :<sup class="text-danger">*</sup></label>
            <textarea name="note" v-model="form.note" placeholder="Enter note"
                      class="form-control form-control2"></textarea>
            <span class="text-danger">{{ errors.note }}</span>
          </div>
        </div>
        <div class="row">
          <b-button class="mt-3" variant="outline-danger" block @click="toggleModal">Ok</b-button>
          <b-button class="mt-2" variant="outline-warning" block @click="hideModal">Cancel</b-button>
        </div>
      </b-modal>
      <b-modal ref="addContact-modal"
               @show="resetModal"
               @hidden="resetModal" id="modal-center-primary" size="xl" v-if="subdomain == 'waterski'"  v-bind:hide-footer="true" centered :title="fields.title">
        <div class="card-body">
          <form ref="form" method="post" id="MemerSearchForm">
            <div class="row">
              <div class="col-md-4">
                <label><strong>{{ contact.title }}</strong></label>
                <div class="form-group">
                  <div class="search-left">
                    <label class="form-label"> {{ fields.search_by_member.member_id.label }}</label>
                    <input type="text" class="form-control" maxlength="20" name="memberLookupForm.member_id" v-model="memberLookupForm.member_id" id="searchbyname"  >
                    <span class="text-sm text-danger">{{ errors.member_id }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <strong>OR</strong>
              </div>
              <div class="col-md-12">
                <label><strong>{{ fields.search_by_name.label }}</strong></label>
              </div>
              <div class="col-md-3" v-if="fields.search_by_name.first_name">

                <div class="form-group">
                  <label for="firstname" class="form-label">{{ fields.search_by_name.first_name.label }}</label>
                  <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="memberLookupForm.first_name" v-model="memberLookupForm.first_name" value="">
                  <span class="text-sm text-danger">{{ errors.first_name }}</span>
                </div>
              </div>
              <div class="col-md-3" v-if="fields.search_by_name.last_name">
                <div class="form-group">
                  <label for="lastname" class="form-label">{{ fields.search_by_name.last_name.label }}</label>
                  <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="memberLookupForm.last_name" v-model="memberLookupForm.last_name">
                  <span class="text-sm text-danger">{{ errors.last_name }}</span>
                </div>
              </div>
              <div class="col-md-3" v-if="fields.search_by_name.city">
                <div class="form-group">
                  <label for="city" class="form-label">{{ fields.search_by_name.city.label }}</label>
                  <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="memberLookupForm.city" v-model="memberLookupForm.city">
                  <span class="text-sm text-danger">{{ errors.city }}</span>
                </div>
              </div>
              <div class="col-md-3" v-if="fields.search_by_name.state">
                <div class="form-group">
                  <label for="state" class="form-label">{{ fields.search_by_name.state.label }}</label>
                  <select class="form-control" id="state" name="memberLookupForm.state" v-model="memberLookupForm.state">
                    <option value="">Please select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state }}</span>
                </div>
              </div>
              <div class="col-md-12 modal-searchbtn text-center">
                <div class="form-group">
                  <button type="button" @click="memberSearch();" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                </div>
                <div class="text-sm text-danger">{{ errors.not_found }}</div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div v-if="memberSerchResultStatus == true" class="card-body primary-contact">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">Member#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(member, r) in memberSearchResults" :key="r">
                  <th scope="row">{{ member.confirmation_code }}</th>
                  <td>{{ member.personal_details.first_name }}</td>
                  <td>{{ member.personal_details.last_name }}</td>
                  <td>{{ member.contact_details.email }}</td>
                  <td>{{ member.contact_details.phone_1 }}</td>
                  <td>{{ member.contact_details.city }}</td>
                  <td>{{ member.contact_details.state_code }}</td>
                  <td><button class="btn btn-primary btn-success" @click="pushSelectedMember(member,contact.type)">Add</button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </b-modal>
      <b-modal ok-only
               v-if="subdomain == 'waterski'"
               ok-title="Add Site Information"
               ref="addSiteInformationModal"
               @show="resetModalClubAddress"
               @hidden="resetModalClubAddress"
               @ok="handleOkClubAddress" id="modal-club-address" size="lg" centered title="Site Information">
        <form ref="form" @submit.stop.prevent="handleSubmitClubAddress">
          <div class="popupbody row">
            <div class="col-md-6" v-if="clubFields.club_info.school">
              <div class="form-group">
                <label for="school" class="form-label">{{ clubFields.club_info.school.label }} <i class="text-danger"  v-if="clubFields.club_info.school.required">*</i></label>
                <input type="text" class="form-control" maxlength="45" id="school" aria-describedby="school" placeholder="Enter Specific site" name="form.school" v-model="address.school">
                <span class="text-sm text-danger">{{ errors.school }}</span>
              </div>
            </div>

            <div class="col-md-6" v-if="clubFields.club_info.membership_sign_social">
              <div class="form-check mt-4">
                <input type="checkbox" id="membership_sign_social" name="form.membership_sign_social" class="form-check-input" v-model="address.is_primary" value="0">
                <label for="membership_sign_social" class="form-check-label font-13">{{ clubFields.club_info.membership_sign_social.label }} <i class="text-danger"  v-if="clubFields.club_info.membership_sign_social.required">*</i></label>
              </div>
            </div>
            <div class="col-md-6" v-if="clubFields.club_info.address">
              <div class="form-group">
                <label for="address" class="form-label">{{ clubFields.club_info.address.label }} <i class="text-danger"  v-if="clubFields.club_info.address.required">*</i> </label>
                <input type="text" class="form-control" maxlength="45" id="address" name="address.address" v-model="address.address">
                <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
              </div>
            </div>
            <div class="col-md-6" v-if="clubFields.club_info.address2">
              <div class="form-group">
                <label for="address" class="form-label">{{ clubFields.club_info.address2.label }} <i class="text-danger"  v-if="clubFields.club_info.address2.required">*</i> </label>
                <input type="text" class="form-control" maxlength="45" id="address2" name="address.address2" v-model="address.address2">
                <!--<span class="text-sm text-danger">{{ errors.address }}</span>-->
              </div>
            </div>
            <div class="col-md-6" v-if="clubFields.club_info.city">
              <div class="form-group">
                <label for="city" class="form-label">{{ clubFields.club_info.city.label }}  <i class="text-danger"  v-if="clubFields.club_info.city.required">*</i></label>
                <input type="text" class="form-control" maxlength="45" id="city" name="form.city" v-model="address.city">
                <span class="text-sm text-danger">{{ errors.city }}</span>
              </div>
            </div>

            <div class="col-md-6" v-if="clubFields.club_info.state">
              <div class="form-group">
                <label for="state" class="form-label">{{ clubFields.club_info.state.label }} <i class="text-danger" v-if="clubFields.club_info.state.required">*</i></label>
                <select class="form-control" id="state" name="form.state_code" v-model="address.state_code">
                  <option value="">Select</option>
                  <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                </select>
                <span class="text-sm text-danger">{{ errors.state_code }}</span>
              </div>
            </div>

            <div class="col-md-6" v-if="clubFields.club_info.zip">
              <div class="form-group">
                <label for="zip" class="form-label">{{ clubFields.club_info.zip.label }} <i class="text-danger" v-if="clubFields.club_info.zip.required">*</i></label>
                <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip"  name="form.zip" v-model="address.zip">
                <span class="text-sm text-danger">{{ errors.address_zip }}</span>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </section>
</template>
<link href="select2.min.css" rel="stylesheet"/>
<script src="jquery-3.2.1.min.js"></script>
<script src="select2.min.js"></script>
<div id="app"></div>

<script type="text/javascript">
import moment from 'moment';
import axios from "axios";
import DatePick from "vue-date-pick";
import 'vue-date-pick/dist/vueDatePick.css';
import $ from 'jquery';
import CustomAlert from "../CustomAlert";
import select2ajax from '../ClubSelect2Ajax';


const validateField = (value, field) => {

  if (value == '' || value == null) {
    return {valid: false, error: field + " is required"};
  }
  return {valid: true, error: null};
};
const validateIDField = (value, value1, field) => {
  if (value1 == '' || value1 == null) {
    return {valid: false, error: field + " is required"};
  }
  else if(field == "Club Name/ID" && value == value1)
  {
    return {valid: false, error: "Please do not select the same club again."};
  }
  return {valid: true, error: null};
};

const validateContactField = (value, field) => {
  if (value.membership_id == ''||value.membership_id == undefined) {
    return {valid: false, error: field + " is required"};
  }
  return {valid: true, error: null};
};
const validateSIField = (clubsites, form_clubsites, field) => {
  let test = true;
  let valid = {};
  $.each(clubsites, function (ind, value) {
    if (form_clubsites.some(form_clubsite => form_clubsite == value.id) && value.is_primary == 1) {
      test = false;
      valid = {valid: true, error: null};
    }
  });
  if(test){
    if(Object.keys(clubsites).length == 0)
    {
      valid = {valid: true, error: ""};
    }
    else {
      valid = {valid: false, error: "Primary Site Information is required"};
    }
  }
  return valid;

};
const validateStateName = name => {
  if (name == null || name == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = select => {
  if (!select) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateZip = (zipCode) => {
  if(zipCode=="" || zipCode==null)
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(zipCode.length != 5 ){
    return { valid: false, error: "Zip code should be 5 character" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/)){
    return { valid: false, error: "Zip Code Should Be 5 Number Values." };
  }
  return { valid: true, error: null };
}
export default {
  name: "clubMergeDelete",
  components: {DatePick, CustomAlert, select2ajax},
  data() {
    return {
      memberOption: [],
      is_old_data:false,
      is_add_contact_visible:false,
      keep_si_collapse: [],
      delete_si_collapse: [],
      delete_si_key: 0,
      keep_si_key: 0,
      memberPersonal2Details: {
        name: '',
        email: '',
        type: '',
        secondary_type: '',
        website: '',
        address: '',
        address2: '',
        city: '',
        state_code: '',
        zip: '',
      },
      mergeDelete:{ 

      },
      memberPersonal1Details: {
        name: '',
        email: '',
        type: '',
        secondary_type: '',
        website: '',
        address: '',
        address2: '',
        city: '',
        state_code: '',
        zip: '',
      },
      memberPersonalDetailsOld: {
        name: '',
        email: '',
        type: '',
        secondary_type: '',
        website: '',
        address: '',
        address2: '',
        city: '',
        state_code: '',
        zip: '',
      },
      teamContacts2: [],
      teamContacts1: [],
      memberContactDetailsOld: [],
      allClubIsLoaded: false,
      form: {
        member_id1: '',
        member_id2: '',
        treasurerContact2:true,
        secretaryContact2:true,
        presidentContact2:true,
        vpContact2:true,
        note: '',
        assistant:[],
        ski:[],
        junior:[],
        safeSport:[],
        clubSites:[],
        oldClubSitesCopy:[],
        mergeClubData:['insurance','sanction','teamroster']
      },
      errors: {
        name: '',
        email: '',
        type: '',
        secondary_type: '',
        address: '',
        website: '',
        city: '',
        state_code: '',
        address_zip: '',
        member_id1: '',
        member_id2: '',
        note: '',
        clubSite: '',
      },
      copy: {
        name: true,
        email: true,
        type: true,
        secondary_type: true,
        address: true,
        address2: true,
        city: true,
        state_code: true,
        zip: true,
        clubSites: true,
        website: true,
        primaryContact:true,
        skiContact:true,
        assistantContact:true,
        juniorContact:true,
        vpContact:true,
        presidentContact:true,
        treasurerContact:true,
        secretaryContact:true,
        safeSportContact:true,
      },
      fields: null,
      displayAlert: false,
      selected: '2',
      selectedAjax: '-1',
      options: [{text: 'Hello'}, {text: 'World'}],
      optionsAjax: [{id: -1, text: ''}],
      registration1: [],
      registration2: [],
      clubSites1: [],
      clubSitesschool: [],
      clubSitesaddress: [],
      clubSitesaddress2: [],
      clubSitesis_primary: [],
      clubSitescity: [],
      clubSitesstate_code: [],
      clubSiteszip: [],
      count: 0,
      clubSites2Old: null,
      juniorContact20ld: null,
      primaryContact1: null,
      vpContact1: null,
      presidentContact1: null,
      safeSportContact1: null,
      safeSportContact20ld: null,
      skiContact1: null,
      juniorContact1: null,
      secretaryContact1: null,
      treasurerContact1: null,
      assistantContact1: null,
      primaryContact2: {
        membership_id: '',
        first_name: '',
        last_name: '',
        phone_1: '',
        phone_2: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        contact_type: 0,
      },
      vpContact2: null,
      presidentContact2: null,
      safeSportContact2: null,
      skiContact2: null,
      juniorContact2: null,
      secretaryContact2: null,
      treasurerContact2: null,
      assistantContact2: null,
      types: ['ski','junior','assistant'],
      memberLookupForm: {
        member_id: '',
        first_name: '',
        last_name: '',
        city: '',
        state: ''
      },
      button: {
        SearchButton: 'Search'
      },
      primaryContactAdded: false,
      contact: {
        type: null,
        title:null,
        status:false,
      },
      contactDetails:{
        membership_id:null,
        first_name:null,
        last_name:null,
        email:null,
        address1:null,
        address2:null,
        city:null,
        state:null,
        state_code:null,
        country:null,
        zip:null,
        phone_1:null,
        phone_2:null,
        contact_type:null,
      },
      address: {
        school: '',
        is_primary: 0,
        address: '',
        address2: '',
        city: '',
        state: '',
        state_code: '',
        country: 'USA',
        zip: '',
        index: 0,
        team_id:''
      },
      clubAddresses: [],
      edit: false,
      newClubSite:{},
      clubSites:[],
      generateclubSitesid:-1,
      clubSitesCopy:[],
      contactTypes : {
        primaryContact2:0,
        juniorContact2:1,
        presidentContact2:2,
        vpContact2:3,
        secretaryContact2:4,
        treasurerContact2:5,
        skiContact2:6,
        assistantContact2:7,
        safeSportContact2:8
      },

    };
  },

  methods: {

    member2change(event) {
      this.errors = {};
      if (this.form.member_id2 == this.form.member_id1) {
        this.errors.member_id2 = 'Please do not select the same club again.';
        return false;
      }
      this.showLoader = true;
      axios.get(this.basePath + 'api/consolidation/getMemberdetails/' + event, {headers: this.adminHeaders})
          .then((res) => {
            if (res.data.status == 'success') {

              this.registration2 = res.data.data;

              if(this.registration1 != '')
              {
                this.allClubIsLoaded = true;
              }
              else
              {
                this.allClubIsLoaded = false;
              }

              this.memberPersonal2Details = res.data.data.team;

              this.clubSites = res.data.data.team.team_site;
              for(let i=0; i<this.clubSites.length; i++)
              {
                this.keep_si_collapse[this.clubSites[i].id] = true;
              }

              this.clubSitesCopy = Object.assign({}, this.clubSites);
              this.setSiteCopyContact();
              this.getContent(this.memberPersonal2Details.id, 2);

              this.showLoader = false;
              this.contact.status = true;
              return false;
            } else {
              console.log('error');
            }
          })
          .catch((error) => {
            console.log('error');
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    member1change(event) {
      this.errors = {};
      if (this.form.member_id2 == event) {
        this.errors.member_id2 = 'Please do not select the same club again';
        return false;
      }
      this.showLoader = true;
      axios.get(this.basePath + 'api/consolidation/getMemberdetails/' + event, {headers: this.adminHeaders})
          .then((res) => {
            if (res.data.status == 'success') {
              this.registration1 = res.data.data;
              if(this.registration2 != '')
              {
                this.allClubIsLoaded = true;
              }
              else
              {
                this.allClubIsLoaded = false;
              }
              this.memberPersonal1Details = res.data.data.team;
              this.clubSites1 = res.data.data.team.team_site;
              var clubSitesIds=[];
              for(let i=0; i<this.clubSites1.length; i++)
              {
                this.clubSitesschool[i] =this.clubSites1[i].school;
                this.clubSitesis_primary[i] = this.clubSites1[i].is_primary;
                this.clubSitesaddress[i] = this.clubSites1[i].address;
                this.clubSitesaddress2[i] = this.clubSites1[i].address2;
                this.clubSitescity[i] = this.clubSites1[i].city;
                this.clubSitesstate_code[i] = this.clubSites1[i].state_code;
                this.clubSiteszip[i] = this.clubSites1[i].zip;
                this.clubSites1[i].count = i;
                this.delete_si_collapse[this.clubSites1[i].id] = true;
                clubSitesIds.push(this.clubSites1[i].id);
              }
              this.form.clubSites1=clubSitesIds;
              this.getContent(this.memberPersonal1Details.id, 1);
              this.showLoader = false;
              return false;

            } else {
              console.log('error');
            }
          })
          .catch((error) => {
            console.log('error');
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    mergeAndDelete() {
      if (this.checkboxValidation() == true) {
        this.$refs['verify-modal'].show()
      } else {
        return false;
      }
    },
    checkboxValidation() {
      this.errors = {};
      this.valid = false;

      const validMemberId2 = validateIDField(this.form.member_id1, this.form.member_id2, "Club Name/ID");
      this.errors.member_id2 = validMemberId2.error;

      const validCity = validateField(this.memberPersonal2Details.city, "City");
      this.errors.city = validCity.error;

      const validState = validateField(this.memberPersonal2Details.state_code, "State");
      this.errors.state_code = validState.error;

      const validClubType = validateField(this.memberPersonal2Details.type, "Primary Club Type");
      this.errors.type = validClubType.error;

      const validName = validateField(this.memberPersonal2Details.name, "Club Name");
      this.errors.name = validName.error;

      const validEmail = validateField(this.memberPersonal2Details.email, "Club E-Mail");
      this.errors.email = validEmail.error;

      const validAddress = validateField(this.memberPersonal2Details.address, "Club Mailing Address");
      this.errors.address = validAddress.error;

      const validzip = validateZip(this.memberPersonal2Details.zip);
      this.errors.zip = validzip.error;

      const validSiteInformation = validateSIField(this.clubSites, this.form.clubSites, "Site Information");

      this.errors.clubSite = validSiteInformation.error;
      console.log(this.primaryContact2);
      const validPrimaryContact = validateContactField(this.primaryContact2, "Primary contact");
      this.errors.primaryContact = validPrimaryContact.error;


      if(validMemberId2.valid && validName.valid && validEmail.valid && validCity.valid && validState.valid && validAddress.valid && validzip.valid && validSiteInformation.valid && validPrimaryContact.valid && validClubType.valid) {
        return true;
      } else {
        return false;
      }
    },
    collapseDeleteChange(i){
      this.delete_si_key++;
      this.delete_si_collapse[i] = !this.delete_si_collapse[i];
    },
    collapseChange(i){
      this.keep_si_key++;
      this.keep_si_collapse[i] = !this.keep_si_collapse[i];
    },
    dataCopy(field) {
      if(this.allClubIsLoaded)
      {
        this.form.treasurerContact2 = field == 'treasurerContact'?true:this.form.treasurerContact2;
        this.form.secretaryContact2 = field == 'secretaryContact'?true:this.form.secretaryContact2;
        this.form.vpContact2 = field == 'vpContact'?true:this.form.vpContact2;
        this.form.presidentContact2 = field=='presidentContact'? true:this.form.presidentContact2;
        this.is_old_data = true;
        var fields= ['clubSites','primaryContact','presidentContact','vpContact','secretaryContact','treasurerContact','safeSportContact','juniorContact','skiContact','assistantContact'];
        if (fields.includes(field)) {
          var oldField =field+'2Old';
          var field1 =field+'1';
          var field2 =field+'2';
          this[oldField] = this[field2];
          this[field2] = Object.assign({}, this[field1]);

          if(['juniorContact','skiContact','assistantContact','safeSportContact'].includes(field)){
            var copyField =field+'Copy';
            this[copyField] = Object.assign({}, this[field1]);
            this.setCopyContact();
          }
          else if(field == 'clubSites'){
            this[oldField] = this.clubSites;
            this.oldClubSitesCopy = Object.assign({}, this.clubSitesCopy);
            this.clubSites = Object.assign({}, this.clubSites1);
            let temp_club_site = [];
            let temp_form_club_site = this.form.clubSites1;
            let temp_keep_si_collapse = [];
            let i = 0;
            this.keep_si_collapse = [];
            $.each(this.clubSites, function (ind, value) {
              temp_keep_si_collapse[value.id] = true;
              if(temp_form_club_site.some(form_clubsite => form_clubsite == value.id))
              {
                temp_club_site[i] = value;
                i++;
              }
            });
            this.keep_si_collapse = temp_keep_si_collapse;
            this.clubSitesCopy = Object.assign({}, temp_club_site);
            this.setSiteCopyContact();
          }
        } else {
          this.memberPersonalDetailsOld[field] = this.memberPersonal2Details[field];
          this.memberPersonal2Details[field] = this.memberPersonal1Details[field];
        }
        this.copy[field] = false;
      }
    },
    oldData(field) {
      if(this.allClubIsLoaded)
      {
        this.form.treasurerContact2 = field == 'treasurerContact'? true:this.form.treasurerContact2;
        this.form.secretaryContact2 = field == 'secretaryContact'? true:this.form.secretaryContact2;
        this.form.vpContact2 = field == 'vpContact'? true:this.form.vpContact2;
        this.form.presidentContact2 = field=='presidentContact'? true:this.form.presidentContact2;
        this.is_old_data = false;
        var oldField =field+'2Old';
        var field2 =field+'2';
        var fields= ['clubSites','primaryContact','presidentContact','vpContact','secretaryContact','treasurerContact','safeSportContact','juniorContact','skiContact','assistantContact'];
        if (fields.includes(field)) {
          this[field2] = this[oldField];
          if(['juniorContact','skiContact','assistantContact','safeSportContact'].includes(field)){
            var copyField =field+'Copy';
            this[copyField] = Object.assign({}, this[oldField]);
            this.setCopyContact();
          }
          else if(field == 'clubSites'){
            this.clubSites= this[oldField];
            this.clubSitesCopy = Object.assign({}, this.oldClubSitesCopy);
            this.keep_si_collapse = [];
            for(let i=0; i<this.clubSites.length; i++)
            {
              this.keep_si_collapse[this.clubSites[i].id] = true;
            }
            this.setSiteCopyContact();
          }
        } else {
          this.memberPersonal2Details[field] = this.memberPersonalDetailsOld[field];
        }
        this.copy[field] = true;
      }
    },
    toggleModal() {
      if (this.checkNote() == true) {
        this.showLoader = true;
        this.$refs['verify-modal'].hide();
        if(!this.form.treasurerContact2)
        {
          this.treasurerContact2 = []
        }
        if(!this.form.secretaryContact2)
        {
          this.secretaryContact2 = []
        }
        if(!this.form.vpContact2)
        {
          this.vpContact2 = []
        }
        if(!this.form.presidentContact2)
        {
          this.presidentContact2 = []
        }
        var MergeData = [this.memberPersonal1Details, this.memberPersonal2Details, this.clubSites1, this.clubSitesCopy, this.form, this.vpContact1, this.presidentContact1,
          this.safeSportContact1, this.skiContact1, this.juniorContact1, this.secretaryContact1, this.treasurerContact1, this.assistantContact1, this. primaryContact2,
          this.vpContact2, this.presidentContact2, this.secretaryContact2, this.treasurerContact2, this.safeSportContactCopy, this.juniorContactCopy, this.skiContactCopy,
          this.assistantContactCopy,
      ];
        axios.post(this.basePath + 'api/consolidation/clubConsolidate', MergeData, {headers: this.adminHeaders})
            .then((res) => {
              if (res.data.status == 'success') {
                this.alertMessage = "Consolidated successfully";
                this.displayAlert = true;
                setTimeout(function () {
                  this.showLoader = false;
                  location.reload();
                }, 3000);

              } else {
                console.log(res);
              }
            })
            .catch((error) => {
              console.log('Error:' + error);
            }).finally(() => {
          console.log('Finally');
        });
      } else {
        return false;
      }
    },
    hideModal() {
      this.$refs['verify-modal'].hide();
      return false;
    },
    checkNote() {
      this.errors = {};
      this.valid = false;

      const validNote = validateField(this.form.note, "Note");
      this.errors.note = validNote.error;
      if (validNote.valid) {
        return true;
      } else {
        return false;
      }
    },
    getClubType() {
      axios.get(this.basePath + "api/getClubType")
          .then((response) => {
            var clubTypes = response.data.data;
            this.clubType = clubTypes;
          })
          .catch(error => {
            console.log("error getting clubs types")
          })
    },
    contactDelete(type) {
      var oldtype= type+'Old';
      var type= type+'2';
      if (this[oldtype] == null) {
        this[oldtype] = this[type];
      }
      this[type]=null;
    },
    contactDelete2(type,data) {
      var oldtype= type+'Old';
      var type= type+'2';
      if (this[oldtype] == null) {
        this[oldtype] = this[type];
      }
      this[type].splice(data, 1);
    },
    getContent(id, member) {
      axios.get(this.basePath + 'api/consolidation/getContacts/' + id, {headers: this.adminHeaders})
          .then((response) => {
            var contact = response.data.data;
            if (member == 1) {
              this.primaryContact1 = (contact.primary_contact == null) ? null : contact.primary_contact;
              this.juniorContact1 = (contact.junior_development == null) ? null : contact.junior_development;
              this.presidentContact1 = (contact.president_info == null) ? null : contact.president_info;
              this.vpContact1 = (contact.vp_info == null) ? null : contact.vp_info;
              this.secretaryContact1 = (contact.secretary_info == null) ? null : contact.secretary_info;
              this.treasurerContact1 = (contact.treasurer_info == null) ? null : contact.treasurer_info;
              this.skiContact1 = (contact.ski_directors == null) ? null : contact.ski_directors;
              this.assistantContact1 = (contact.assistant_skidirectors == null) ? null : contact.assistant_skidirectors;
              this.safeSportContact1 = (contact.safe_sport_coordinator == null) ? null : contact.safe_sport_coordinator;
            } else {
              this.primaryContact2 = (contact.primary_contact == null) ? []: contact.primary_contact;
              this.juniorContact2 = (contact.junior_development == null) ? null : contact.junior_development;
              this.juniorContactCopy = (contact.junior_development == null) ? null : contact.junior_development;
              this.presidentContact2 = (contact.president_info == null) ? [] : contact.president_info;
              this.vpContact2 = (contact.vp_info == null) ? [] : contact.vp_info;
              this.secretaryContact2 = (contact.secretary_info == null) ? [] : contact.secretary_info;
              this.treasurerContact2 = (contact.treasurer_info == null) ? [] : contact.treasurer_info;
              this.skiContact2 = (contact.ski_directors == null) ? null : contact.ski_directors;
              this.skiContactCopy = (contact.ski_directors == null) ? null : contact.ski_directors;
              this.assistantContact2 = (contact.assistant_skidirectors == null) ? null : contact.assistant_skidirectors;
              this.assistantContactCopy = (contact.assistant_skidirectors == null) ? null : contact.assistant_skidirectors;
              this.safeSportContact2 = (contact.safe_sport_coordinator == null) ? null : contact.safe_sport_coordinator;
              this.safeSportContactCopy = (contact.safe_sport_coordinator == null) ? null : contact.safe_sport_coordinator;
              this.setCopyContact();
            }
          })
          .catch(error => {
            console.log("error getting clubs types")
          })
    },
    mergeClubDataChange(value) {
      var names = value.target.name;
      var name = names.split(",");
      var check = value.target.checked;
      var id = value.target.value;
      var index = 0;
      if(check==true)
      {
        this.form.mergeClubData.push(id);
      }
      else
      {
        $.each(this.form.mergeClubData, function (ind, value) {
          if (id == value) {
            index = ind;
          }
        });
        this.form.mergeClubData.splice(index, 1);
      }
    },
    memberRemove(value){
      var names= value.target.name;
      var name = names.split(",");
      var check = value.target.checked;
     if(name[1] == 'treasurerContact2')
     {
       this.form.treasurerContact2 = check;
     }
      if(name[1] == 'secretaryContact2')
      {
        this.form.secretaryContact2 = check;
      }
      if(name[1] == 'vpContact2')
      {
        this.form.vpContact2 = check;
      }
      if(name[1] == 'presidentContact2')
      {
        this.form.presidentContact2 = check;
      }
    },
    contactChange(value){
      var names= value.target.name;
      var name = names.split(",");
      var oldtype= name[1]+'DeleteOld';
      var data = name[1]+'Contact2';
      var copyType= name[1]+'ContactCopy';
      var check = value.target.checked;
      var id = value.target.value;
      var contacts='';
      var index = 0;
      if(name[1]=='junior')
      {
        $.each(this.form.junior, function (ind, value) {
          if (id == value) {
            contacts=value;
            index = ind;
          }
        });
        if(check==true) {
          if(!this.form.junior.includes(id)){
            this.form.junior.push(id);
            let temp_junior_contact = [];
            $.each(this.juniorContact2, function (ind, value) {
              if(id == value.membership_id)
              {
                temp_junior_contact = value;
              }
            });
            this.juniorContactCopy[this.juniorContactCopy.length] = temp_junior_contact;
          }

        }else {
          this.form.junior.splice(index, 1);
          let temp_junior_contact = [];
          let i = 0;
          $.each(this.juniorContactCopy, function (ind, value) {
            if(id != value.membership_id)
            {
              temp_junior_contact[i] = value;
              i++;
            }
          });
          this.juniorContactCopy = [];
          this.juniorContactCopy = temp_junior_contact;
        }
      }
      if(name[1]=="ski") {
        $.each(this.form.ski, function (ind, value) {
          if (id == value) {
            contacts = value;
            index = ind;
          }
        });
        if (check == true) {
          if (!this.form.ski.includes(id)) {
            this.form.ski.push(id);
            let temp_ski_contact = [];
            $.each(this.skiContact2, function (ind, value) {
              if(id == value.membership_id)
              {
                temp_ski_contact = value;
              }
            });
            this.skiContactCopy[this.skiContactCopy.length] = temp_ski_contact;
          }

        } else {
          this.form.ski.splice(index, 1);
          let temp_ski_contact = [];
          let i = 0;
          $.each(this.skiContactCopy, function (ind, value) {
            if(id != value.membership_id)
            {
              temp_ski_contact[i] = value;
              i++;
            }
          });
          this.skiContactCopy = [];
          this.skiContactCopy = temp_ski_contact;
        }
      }
      if(name[1]=="assistant"){
        $.each(this.form.assistant, function (ind, value) {
          if (id == value) {
            contacts = value;
            index = ind;
          }
        });
        if (check == true) {
          if (!this.form.assistant.includes(id)) {
            this.form.assistant.push(id);
            let temp_assistant_contact = [];
            $.each(this.assistantContact2, function (ind, value) {
              if(id == value.membership_id)
              {
                temp_assistant_contact = value;
              }
            });
            this.assistantContactCopy[this.assistantContactCopy.length] = temp_assistant_contact;
          }

        } else {
          this.form.assistant.splice(index, 1);
          let temp_assistant_contact = [];
          let i = 0;
          $.each(this.assistantContactCopy, function (ind, value) {
            if(id != value.membership_id)
            {
              temp_assistant_contact[i] = value;
              i++;
            }
          });
          this.assistantContactCopy = [];
          this.assistantContactCopy = temp_assistant_contact;
        }
      }
      if(name[1]=="safeSport"){
        $.each(this.form.safeSport, function (ind, value) {
          if (id == value) {
            contacts = value;
            index = ind;
          }
        });
        if (check == true) {
          if (!this.form.safeSport.includes(id)) {
            this.form.safeSport.push(id);
            let temp_safeSport_coordinator = [];
            $.each(this.safeSportContact2, function (ind, value) {
              if(id == value.membership_id)
              {
                temp_safeSport_coordinator = value;
              }
            });
            this.safeSportContactCopy[this.safeSportContactCopy.length] = temp_safeSport_coordinator;
          }
        } else {
          this.form.safeSport.splice(index, 1);
          let temp_safeSport_coordinator = [];
          let i = 0;
          $.each(this.safeSportContactCopy, function (ind, value) {
            if(id != value.membership_id)
            {
              temp_safeSport_coordinator[i] = value;
              i++;
            }
          });
          this.safeSportContactCopy = [];
          this.safeSportContactCopy = temp_safeSport_coordinator;
        }
      }
      if(name[1]=='clubSites'){
        $.each(this.form.clubSites, function (ind, value) {
          if (id == value) {
            contacts=value;
            index = ind;
          }
        });
        if(check==true) {
            this.form.clubSites.push(id);
          let temp_club_site = [];
          $.each(this.clubSites, function (ind, value) {
            if(id == value.id)
            {
              temp_club_site = value;
            }
          });
          this.clubSitesCopy[this.clubSitesCopy.length] = temp_club_site;
        }else {
          this.form.clubSites.splice(index, 1);
          let temp_club_site = [];
          let i = 0;
          $.each(this.clubSitesCopy, function (ind, value) {
            if(id != value.id)
            {
              temp_club_site[i] = value;
              i++;
            }
          });
          this.clubSitesCopy = [];
          this.clubSitesCopy = temp_club_site;
        }
      }
      if(name[1]=='clubSites1'){
        $.each(this.form.clubSites1, function (ind, value) {
          if (id == value) {
            index = ind;
          }
        });
        if(check==true) {
          this.form.clubSites1.push(id);
        }else {
          this.form.clubSites1.splice(index, 1);
        }
      }
      if (this[oldtype] == null) {
        this[oldtype] = this[data];
      }

      },
    setSiteCopyContact(){
      var clubSitesIds=[];
      $.each(this.clubSitesCopy, function (ind, value) {
        clubSitesIds.push(value.id);
      });
      this.form.clubSites=clubSitesIds;
    },
    setCopyContact(){
      var skiIds=this.form.ski;
      $.each(this.skiContact2, function (ind, value) {
            if(!skiIds.includes(value.membership_id)) {
              skiIds.push(value.membership_id);
            }
      });
      this.form.ski=skiIds;
      this.skiContactCopy = Object.assign({}, this.skiContact2);
      var safeSportIds = this.form.safeSport;
      $.each(this.safeSportContact2, function (ind, value) {
            if(!safeSportIds.includes(value.membership_id)) {
              safeSportIds.push(value.membership_id);
            }
      });
      this.form.safeSport = safeSportIds;
      this.safeSportContactCopy = Object.assign({}, this.safeSportContact2);
      var juniorIds=this.form.junior;
      $.each(this.juniorContact2, function (ind, value) {
        if(!juniorIds.includes(value.membership_id)){
          juniorIds.push(value.membership_id);
        }
      });
      this.form.junior=juniorIds;
      this.juniorContactCopy = Object.assign({}, this.juniorContact2);
      var assistantIds=this.form.assistant;
      $.each(this.assistantContact2, function (ind, value) {
            if(!assistantIds.includes(value.membership_id)) {
              assistantIds.push(value.membership_id);
            }
      });
      this.form.assistant=assistantIds;
      this.assistantContactCopy = Object.assign({}, this.assistantContact2);
    },
    addContact(contact,title) {
      this.memberSerchResultStatus = false;
      this.memberLookupForm = {
            member_id: '',
            first_name: '',
            last_name: '',
            city: '',
            state: ''
      };
      this.$refs['addContact-modal'].show();
      this.contact.type = contact;
      this.contact.title = title;
    },
    memberSearch: function () {
      if(!this.validate()) {
        return;
      }
      this.button.SearchButton = 'Searching...';
      this.errors= {};
      axios.get(this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id+'&first_name='+this.memberLookupForm.first_name+'&last_name='+this.memberLookupForm.last_name+'&city='+this.memberLookupForm.city+'&state='+this.memberLookupForm.state)
          .then((res) => {
            if(res.data.status == 'success') {
              this.button.SearchButton = 'Search';
              this.memberSerchResultStatus = true;
              this.memberSearchResults = res.data.data;
            } else {
              this.errors.not_found = 'Member Not Found!';
              this.button.SearchButton = 'Search';
              return false;
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.errors.not_found = 'Member Not Found!';
            //this.step--;
            this.button.SearchButton = 'Search';
            return false;
            // error.response.status Check status code
          }).finally(() => {


      });
    },
    validate() {
      this.errors = {}
      const member_id = this.memberLookupForm.member_id;
      const first_name = this.memberLookupForm.first_name;
      const last_name = this.memberLookupForm.last_name;
      const city = this.memberLookupForm.city;
      const state = this.memberLookupForm.state;
      if(member_id=="" && first_name == "" && last_name == "" && city == "" && state == "") {
        this.errors.member_id = 'Plesse Enter Either Member Id or Below Member Details';

        return this.valid;
      }
      if(member_id == '' && (first_name == '' || last_name == '' || city == '' || state == '')) {
        const validFirstName = validateField(this.memberLookupForm.first_name,'First Name');
        this.errors.first_name = validFirstName.error;

        const validLastName = validateField(this.memberLookupForm.last_name,'Last Name');
        this.errors.last_name = validLastName.error;

        const validCity = validateField(this.memberLookupForm.city,'City');
        this.errors.city = validCity.error;

        const validStateCode = validateField(this.memberLookupForm.state,'State');
        this.errors.state = validStateCode.error;

        return this.valid;
      }

      this.valid = true;
      this.errors= {}

      return this.valid;
    },
    pushSelectedMember: function(member,type){
      let contactType = ['assistantContact2', 'skiContact2', 'juniorContact2', 'safeSportContact2'];
      let memberContact = member.contact_details;
      this.primaryContactAdded = true;
      this.contactDetails = [];
      let tempcontact = [];
      let is_already_added = false;
      if(contactType.includes(this.contact.type)) {
        this.contactDetails.id = member.confirmation_code;
        this.contactDetails.team_id = this.registration2.team_id;
        this.contactDetails.contact_type = this.contactTypes[type];
        this.contactDetails.first_name = member.personal_details.first_name;
        this.contactDetails.last_name = member.personal_details.last_name;
        this.contactDetails.membership_id = member.confirmation_code;
        this.contactDetails.phone_1 = memberContact.phone_1;
        this.contactDetails.email = memberContact.email;
        this.contactDetails.address1 = memberContact.address1;
        this.contactDetails.address2 = memberContact.address2;
        this.contactDetails.city = memberContact.city;
        this.contactDetails.state = memberContact.state;
        this.contactDetails.state_code = memberContact.state_code;
        this.contactDetails.country = memberContact.country;
        this.contactDetails.zip = memberContact.zip;

        this.contactDetails.phone_2 = memberContact.phone_2;
        this.contactDetails.full_name = member.personal_details.first_name +' ' +member.personal_details.last_name;
        for(let i=0; i<this[type].length; i++)
        {
          if(this[type][i].membership_id == this.contactDetails.id){
            is_already_added = true;
          }
        }
        if(!is_already_added){
          this[type][Object.keys(this[type]).length] = Object.assign({}, this.contactDetails);
        }
        this.setCopyContact();
      }
      else {
        tempcontact.id = '';
        tempcontact.membership_id = member.confirmation_code;
        tempcontact.team_id = this.registration2.team_id;
        tempcontact.contact_type = this.contactTypes[type];
        tempcontact.first_name = member.personal_details.first_name;
        tempcontact.last_name = member.personal_details.last_name;
        tempcontact.phone_1 = memberContact.phone_1;
        tempcontact.email = memberContact.email;
        tempcontact.address1 = memberContact.address1;
        tempcontact.address2 = memberContact.address2;
        tempcontact.city = memberContact.city;
        tempcontact.state = memberContact.state;
        tempcontact.state_code = memberContact.state_code;
        tempcontact.country = memberContact.country;
        tempcontact.zip = memberContact.zip;

        tempcontact.full_name = member.personal_details.first_name +' ' +member.personal_details.last_name;
        tempcontact.phone_1 = memberContact.phone_1;

        this[type] = Object.assign({}, tempcontact);

        this.$emit('club-primary-data', this[type]);
      }
      this.valid = true;
      this.contactAdded = true;
      this.$nextTick(() => {
        this.$bvModal.hide('modal-center-primary')
        this.memberSerchResultStatus = [];
        this.memberLookupForm = {
          member_id: '',
          first_name: '',
          last_name: '',
          city: '',
          state: ''
        };
      })
      this.errors.primary = '';
    },
    addSiteInformation() {
      this.$refs['addSiteInformationModal'].show();
      this.edit = false;
    },
    handleOkClubAddress(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitClubAddress()
    },
    handleSubmitClubAddress() {
      if (!this.addressValidate()) {
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-club-address')
      })
      let newClubSite = [];
      newClubSite.id = this.generateclubSitesid;
      this.generateclubSitesid--;
      newClubSite.team_id= this.registration2.team_id;
      newClubSite.school= this.address.school;
      newClubSite.state_code= this.address.state_code;
      newClubSite.city= this.address.city;
      newClubSite.zip= this.address.zip;
      newClubSite.address= this.address.address;
      newClubSite.address2= this.address.address2;
      newClubSite.is_primary = null;
      newClubSite.primary_club= 'No';
      if (this.address.is_primary==true) {
        newClubSite.is_primary = 1;
        newClubSite.primary_club= 'Yes';
      }
      newClubSite.state= null;
      newClubSite.usa_state= this.address.state_code;
      newClubSite.country= 'USA';
      this.clubSites[Object.keys(this.clubSites).length] = Object.assign({}, newClubSite);
      this.clubSitesCopy[Object.keys(this.clubSites).length] = Object.assign({}, newClubSite);
      this.form.clubSites.push(newClubSite.id);
      this.address = {
        school: '',
        is_primary: 0,
        address: '',
        address2: '',
        city: '',
        state: '',
        state_code: '',
        country: 'USA',
        zip: '',
        index: 0,
        team_id: ''
      };
    },
    addressValidate() {
      this.errors = {}
      this.valid = false;
      const validSchool = validateStateName(this.address.school);
      this.errors.school = validSchool.error;

      //const validAddress = validateStateName(this.address.address);
      //this.errors.address = validAddress.error;

      const validStateCode = validateSelect(this.address.state_code);

      const validCity = validateStateName(this.address.city);
      this.errors.city = validCity.error;

      const validZip = validateZip(this.address.zip);
      this.errors.address_zip = validZip.error;

      if (validSchool.valid && validStateCode.valid && validCity.valid && validZip.valid) {
        this.addressValid = true;
      }

      return this.addressValid;
    },
    setCopy(){
      var contact = response.data.data;
      this.juniorContactCopy= (contact.junior_development == null) ? null : contact.junior_development;
      this.skiContactCopy = (contact.ski_directors == null) ? null : contact.ski_directors;
      this.assistantContactCopy = (contact.assistant_skidirectors == null) ? null : contact.assistant_skidirectors;
    }
  },
  mounted() {
    this.getClubType();
    const retry = setInterval(() => {

      this.fields = this.getSiteProps('memberRegistration.club.fields.club_contact');
      this.clubFields = this.getSiteProps('memberRegistration.club.fields.club_info');
      if (this.fields) clearInterval(retry);
    }, 2000);
    console.log(this.clubSites);
  },
  computed: {},
  created() {
    $('#select2ajax-template').on('change', function () {
    });
  }

}
</script>

<style>
.table-area th {
  width: auto !important;
}

.icon-design {
  font-size: 30px;
  color: #007bff;
  margin-top: 4px
}

.select2-selection {
  border-radius: 0px !important;
  text-align: left !important;
}

.select2-dropdown {
  border-radius: 0px !important;
}

.form-control2 {
  background: #fff !important;
}
.gray-out-td{
  background: #e9ecef !important;
}
</style>